import type { ApiImageProps } from '../components/UI/Image';
import type {
  AreaItem,
  ArticleHeadDataItems,
  ArticleHeadFields,
  ArticleHeadSection,
  MarketingDataItem,
  MarketingFields,
  SectionDataItem,
  SectionDataItemFields,
  TrackingFields,
} from '../types/cms';

const MAX_META_IMAGE_WIDTH = 1200;

export const isArticle = (item: AreaItem) => {
  return (
    item.type === 'storyline' ||
    item.type === 'video' ||
    item.type === 'sponsored-article' ||
    item.type === 'personas' ||
    item.type === 'topic'
  );
};

export const isSectionData = (item: AreaItem) => {
  return item.type === 'sectionData';
};

export const getSectionDataByAttributeValue = (attributeValue: keyof SectionDataItemFields, data?: Array<AreaItem>) => {
  return (
    (data ?? [])
      //.filter((item: AreaItem) => item.template == 'metadata')
      .flatMap((item) => item.items)
      .filter(isSectionData)
      .reduce((acc, item: SectionDataItem) => item.model?.fields?.[attributeValue] ?? acc, undefined)
  );
};

export const getArticleSections = (data?: Array<AreaItem>): Array<ArticleHeadSection> => {
  return (data ?? [])
    .filter((areaItem: AreaItem) => areaItem.view == 'head')
    .flatMap((item) => item.items)
    .filter(isArticle)
    .reduce((acc, item: ArticleHeadDataItems) => [...acc, ...(item.model?.sections ?? [])] ?? [], []);
};

export const getArticleHeadDataByAttribute = (attributeValue: keyof ArticleHeadFields, data?: Array<AreaItem>) => {
  return (data ?? [])
    .filter((areaItem: AreaItem) => areaItem.view == 'head')
    .reduce(
      (_accu, areaItem) =>
        areaItem.items
          .filter(isArticle)
          .map((item: ArticleHeadDataItems) => item.model.fields?.[attributeValue])
          .pop(),
      undefined
    );
};

const createMetaImage = (image: ApiImageProps | undefined) => {
  if (!image) {
    return undefined;
  }

  const width = Math.min(image.originalWidth, MAX_META_IMAGE_WIDTH);
  const height = Math.round(width * 0.5625);

  return {
    url: `${image.baseUrl}c16_9/${width}/${image.filename}.jpg`,
    width,
    height,
  };
};

export const getMetaImage = (_pagetype?: string, data?: Array<AreaItem>) => {
  return (data ?? [])
    .filter((areaItem: AreaItem) => areaItem.view == 'head')
    .flatMap((item) => item.items)
    .filter(isArticle)
    .reduce(
      (acc, item: ArticleHeadDataItems) => createMetaImage(item.model['social-image'] ?? item.model.image) ?? acc,
      {
        url: '/rtl_logo.png',
        width: 1200,
        height: 630,
      }
    );
};

export const getMarketingDataByAttribute = (attributeValue: keyof MarketingFields, data?: Array<AreaItem>) => {
  return (data ?? [])
    .filter((areaItem: AreaItem) => areaItem.template == 'marketing')
    .reduce((accu, item: MarketingDataItem) => item.model?.marketing?.[attributeValue] ?? accu, undefined);
};

export const getTrackingDataByAttribute = (attributeValue: keyof TrackingFields, data?: Array<AreaItem>) => {
  return (data ?? [])
    .filter((areaItem: AreaItem) => areaItem.template == 'analytics')
    .reduce((accu, item) => item.model?.tracking?.[attributeValue] ?? accu, undefined);
};
